/* eslint-disable no-new */
/* eslint-disable import/no-extraneous-dependencies */
import utils, { docReady } from './utils';

import choicesInit from './theme/choices';
import featherIconsInit from './theme/featherIcons';
import handleNavbarVerticalCollapsed from './theme/navbar-vertical';

docReady(choicesInit);
docReady(featherIconsInit);
docReady(handleNavbarVerticalCollapsed);

export default {
  utils
};
